.vertical-flex {
  display: flex;
  flex-direction: column;
}
.horizontal-flex {
  display: flex;
}
.flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-content {
  width: calc(25% - 56px);
  display: flex;
  align-items: flex-end;
}
.filter-button {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}
.filter-button button {
  margin: 0 !important;
  min-height: 40px;
}
.filter-content label {
  width: 100%;
}
.card-body input,
.card-body select {
  min-height: 40px;
}
.filter-block {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin-bottom: 40px;
}
.add-inventory-block {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
  margin-bottom: 20px;
}
.add-inventory-block .col-lg-6 {
  width: 100%;
}
.add-inventory-block input {
  width: 100%;
}
.flex-div {
  width: calc(50% - 20px);
  flex-direction: column;
  display: flex;
}
.full-width-block {
  width: 100%;
}
a.nav-link.nav-group-toggle {
  white-space: unset;
}
a.nav-link.nav-group-toggle:after {
  min-width: 12px;
}
.flex-quarter-width {
  width: 25%;
}
.flex-end button {
  margin-bottom: 0 !important;
}

.flex-end {
  display: flex;
  justify-content: end;
}
.flex-quarter-width {
  width: 25%;
}
.margin-top-small {
  margin-top: 20px;
}
.product-name .select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}
.product-name .select__value-container {
  margin: 0 !important;
  padding: 0 0 0 10px !important;
}
.product-name input {
  min-height: 37px;
}
td.no-data {
  text-align: center;
  font-weight: 600;
}
.confirm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  gap: 20px;
  font-size: 20px;
}
.confirm-body button {
  font-size: 16px;
  min-width: 100px;
}
p.loading-box {
  margin: 0;
  padding: 6px 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  color: #8b8b8b;
  pointer-events: none;
}
/* .css-13cymwt-control {
  height: 35px;
  
}

.css-1fdsijx-ValueContainer {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: grid;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  padding: 2px 8px;
  box-sizing: border-box;
}

.css-qbdosj-Input {
  padding-bottom: 33px !important; 
  height: 20px;
  margin: 0px; 
  padding-bottom: 0px !important;
   padding-top: 0px;
}
.css-1dimb5e-singleValue {
  margin-top: 2px;
}
.css-1u9des2-indicatorSeparator {

  margin-top: 0px !important;

} */
